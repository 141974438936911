<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    />
    <b10-page-content>
      <b10-view-summary
        :item="formattedItem"
        :showing.sync="showingDialogs.moreInfo"
        :loading-data="loadingData"
        :show-alerts="!routeFromBrowserHistory"
        :more-info-in-dialog="false"
      />
    </b10-page-content>
  </b10-base>
</template>

<script>
import _ from '@/utils/lodash'
import { viewPageMixin } from '@/mixins/viewPageMixin'
import Data from './ContratoCuotaViewData'

export default {
  mixins: [viewPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  data () {
    return {
      moreInfoRows: [
        'descripcion_cuota',
        'inf_comercial',
        'periodo_descripcion',
        { name: 'precio_periodo', filter: this.$options.filters.currency },
        { name: 'precio_mes', filter: this.$options.filters.currency },
        { name: 'dto', filter: this.$options.filters.percent },
        'unidades',
        { name: 'precio_total', filter: this.$options.filters.currency },
        { name: 'opcional', filter: this.$options.filters.humanizeBoolean },
        { name: 'activacion_automatica', filter: this.$options.filters.humanizeBoolean },
        'meses_permanencia',
      ],
      showingDialogs: {
        moreInfo: false,
      },
    }
  },
  computed: {
    formattedItem () {
      if (!_.isEmpty(this.item.dataset)) {
        const item = _.cloneDeep(this.item.dataset)
        item.id = item.contrato_cuota
        item.title = this.$online.contratoCuota.title(item)
        item.subtitle = this.$online.contratoCuota.subtitle(item)
        const badge = this.$online.contratoCuota.badge(item)
        item.badge = badge.badge
        item.badgeColor = badge.badgeColor
        item.moreInfo = this.buildMoreInfo(this.item.metadata)
        return item
      } else {
        return {}
      }
    },
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      await this.loadItem()
      this.title = this.$online.contratoCuota.title(this.item.dataset)
    },
    async loadItem () {
      this.loadingData = true
      try {
        const [dataset, metadata] = await Data.selectContratoCuota(
          this,
          this.routeParams.idcontrato_cuota
        )
        if (dataset) {
          this.$set(this.item, 'dataset', dataset)
          this.$set(this.item, 'metadata', metadata)
        } else {
          this.itemNotFound()
        }
      } finally {
        this.loadingData = false
      }
    },
  },
}
</script>
