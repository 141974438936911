import { APIFilter } from '@/utils/api'

export default {
  async selectContratoCuota (Vue, idcontratoCuota) {
    const apiFilter = new APIFilter()
    apiFilter.addExact('idcontrato_cuota', idcontratoCuota)
    const resp = await Vue.$api.call('contratoCuota.select', { filter: apiFilter })
    return [resp.data.result.dataset[0], resp.data.result.metadata]
  },
}
